import { useNotifications } from 'notifications/hooks/useNotifications';
import { useCallback } from 'react';
import { useMutation } from 'react-query';
import { amApi } from '../api';

const useErrorHandler = () => {
  const { setErrors } = useNotifications();

  const addDefaultErrorHandling = useCallback(
    (options) => {
      const { showErrorNotification = true, onError } = options;
      const showNotification = (response) => {
        if (
          response?.response?.status === 500 ||
          !response.response?.data?.errors
        ) {
          setErrors('Something went wrong. Please try again later');
        } else {
          setErrors(Object.values(response.response?.data?.errors)?.flat());
        }
      };
      return showErrorNotification
        ? {
            ...options,
            onError: (response) => {
              showNotification(response);
              onError?.(response);
            },
          }
        : options;
    },
    [setErrors],
  );
  return addDefaultErrorHandling;
};

export const useLogin = (options) => {
  const { mutate: login, ...rest } = useMutation(
    async ({ email: username, password }) => amApi.login(username, password),
    options,
  );
  return { login, ...rest };
};

export const useRegister = (options) => {
  const addDefaultErrorHandling = useErrorHandler();
  const { mutate: register, ...rest } = useMutation(
    async (payload) => amApi.register(payload),
    addDefaultErrorHandling(options),
  );
  return { register, ...rest };
};

export const useCurrentUserMutation = (options) => {
  const addDefaultErrorHandling = useErrorHandler();
  const { mutate: getApiUser, ...rest } = useMutation(
    async () => amApi.getUser(),
    addDefaultErrorHandling(options),
  );
  return { getApiUser, ...rest };
};

export const useUpdateEmployee = (options) => {
  const addDefaultErrorHandling = useErrorHandler();
  const {
    mutate: updateEmployee,
    mutateAsync: updateEmployeeAsync,
    ...rest
  } = useMutation(
    async ({ id, payload }) => amApi.updateEmployee(id, payload),
    addDefaultErrorHandling(options),
  );
  return { updateEmployee, updateEmployeeAsync, ...rest };
};

export const useCreateEmployee = (options) => {
  const addDefaultErrorHandling = useErrorHandler();
  const {
    mutate: createEmployee,
    mutateAsync: createEmployeeAsync,
    ...rest
  } = useMutation(
    async ({ payload }) => amApi.createEmployee(payload),
    addDefaultErrorHandling(options),
  );
  return { createEmployee, createEmployeeAsync, ...rest };
};

export const useDeleteEmployee = (options) => {
  const addDefaultErrorHandling = useErrorHandler();
  const { mutate: deleteEmployee, ...rest } = useMutation(
    async ({ id }) => amApi.deleteEmployee(id),
    addDefaultErrorHandling(options),
  );
  return { deleteEmployee, ...rest };
};
export const useAddJob = (options) => {
  const addDefaultErrorHandling = useErrorHandler();
  const { mutate: addJob, ...rest } = useMutation(
    async (payload) => amApi.addJob(payload),
    addDefaultErrorHandling(options),
  );
  return { addJob, ...rest };
};
export const useUpdateJob = (options) => {
  const addDefaultErrorHandling = useErrorHandler();
  const { mutate: updateJob, ...rest } = useMutation(
    async ({ id, payload }) => amApi.updateJob(id, payload),
    addDefaultErrorHandling(options),
  );
  return { updateJob, ...rest };
};
export const useDeleteJob = (options) => {
  const addDefaultErrorHandling = useErrorHandler();
  const { mutate: deleteJob, ...rest } = useMutation(
    async ({ id }) => amApi.deleteJob(id),
    addDefaultErrorHandling(options),
  );
  return { deleteJob, ...rest };
};

export const useAddCandidate = (options) => {
  const addDefaultErrorHandling = useErrorHandler();
  const { mutate: addCandidate, ...rest } = useMutation(
    async (payload) => amApi.addCandidate(payload),
    addDefaultErrorHandling(options),
  );
  return { addCandidate, ...rest };
};
export const useUpdateCandidate = (options) => {
  const addDefaultErrorHandling = useErrorHandler();
  const { mutate: updateCandidate, ...rest } = useMutation(
    async ({ id, payload }) => amApi.updateCandidate(id, payload),
    addDefaultErrorHandling(options),
  );
  return { updateCandidate, ...rest };
};
export const useDeleteCandidate = (options) => {
  const addDefaultErrorHandling = useErrorHandler();
  const { mutate: deleteCandidate, ...rest } = useMutation(
    async ({ id }) => amApi.deleteCandidate(id),
    addDefaultErrorHandling(options),
  );
  return { deleteCandidate, ...rest };
};

export const useAddTalentGroup = (options) => {
  const addDefaultErrorHandling = useErrorHandler();
  const { mutate: addTalentGroup, ...rest } = useMutation(
    async (payload) => amApi.addTalentGroup(payload),
    addDefaultErrorHandling(options),
  );
  return { addTalentGroup, ...rest };
};
export const useUpdateTalentGroup = (options) => {
  const addDefaultErrorHandling = useErrorHandler();
  const { mutate: updateTalentGroup, ...rest } = useMutation(
    async ({ id, payload }) => amApi.updateTalentGroup(id, payload),
    addDefaultErrorHandling(options),
  );
  return { updateTalentGroup, ...rest };
};
export const useDeleteTalentGroup = (options) => {
  const addDefaultErrorHandling = useErrorHandler();
  const { mutate: deleteTalentGroup, ...rest } = useMutation(
    async ({ id }) => amApi.deleteTalentGroup(id),
    addDefaultErrorHandling(options),
  );
  return { deleteTalentGroup, ...rest };
};

export const useAddCandidateToPool = (options) => {
  const addDefaultErrorHandling = useErrorHandler();
  const { mutate: addCandidateToPool, ...rest } = useMutation(
    async ({ id, payload }) => amApi.addCandidateToPool(id, payload),
    addDefaultErrorHandling(options),
  );
  return { addCandidateToPool, ...rest };
};
export const useDeleteCandidateToPool = (options) => {
  const addDefaultErrorHandling = useErrorHandler();
  const { mutate: deleteCandidateToPool, ...rest } = useMutation(
    async ({ poolId, candidateId }) =>
      amApi.deleteCandidateToPool(poolId, candidateId),
    addDefaultErrorHandling(options),
  );
  return { deleteCandidateToPool, ...rest };
};

export const useLogout = (options) => {
  const addDefaultErrorHandling = useErrorHandler();
  const { mutate: logout, ...rest } = useMutation(
    async () => amApi.logout(),
    addDefaultErrorHandling(options),
  );
  return { logout, ...rest };
};

export const useCreateNote = (options) => {
  const addDefaultErrorHandling = useErrorHandler();
  const { mutate: createNote, ...rest } = useMutation(
    async (payload) => amApi.createNote(payload),
    addDefaultErrorHandling(options),
  );
  return { createNote, ...rest };
};
export const useUpdateNote = (options) => {
  const addDefaultErrorHandling = useErrorHandler();
  const { mutate: updateNote, ...rest } = useMutation(
    async ({ id, payload, callback }) =>
      amApi.updateNote(id, payload, callback),
    addDefaultErrorHandling(options),
  );
  return { updateNote, ...rest };
};

export const useDeleteNote = (options) => {
  const addDefaultErrorHandling = useErrorHandler();
  const { mutate: deleteNote, ...rest } = useMutation(
    async ({ id }) => amApi.deleteNote(id),
    addDefaultErrorHandling(options),
  );
  return { deleteNote, ...rest };
};

export const useCreateFile = (options) => {
  const addDefaultErrorHandling = useErrorHandler();
  const { mutate: createFile, ...rest } = useMutation(
    async (payload) => amApi.createFile(payload),
    addDefaultErrorHandling(options),
  );
  return { createFile, ...rest };
};

export const useDeleteFile = (options) => {
  const addDefaultErrorHandling = useErrorHandler();
  const { mutate: deleteFile, ...rest } = useMutation(
    async ({ id }) => amApi.deleteFile(id),
    addDefaultErrorHandling(options),
  );
  return { deleteFile, ...rest };
};

export const useMarkAsRead = (options) => {
  const addDefaultErrorHandling = useErrorHandler();
  const { mutate: markAsRead, ...rest } = useMutation(
    async ({ userId, notificationId }) =>
      amApi.markAsRead({ userId, notificationId }),
    addDefaultErrorHandling(options),
  );
  return { markAsRead, ...rest };
};
export const useDownloadFile = (options) => {
  const addDefaultErrorHandling = useErrorHandler();
  const { mutate: downloadFile, ...rest } = useMutation(
    async (file) => amApi.downloadFile(file),
    addDefaultErrorHandling(options),
  );
  return { downloadFile, ...rest };
};

export const useCreateTraining = (options) => {
  const addDefaultErrorHandling = useErrorHandler();
  const { mutate: createTraining, ...rest } = useMutation(
    async (payload) => amApi.createTraining(payload),
    addDefaultErrorHandling(options),
  );
  return { createTraining, ...rest };
};

export const useCreateEmployeeRequest = (options) => {
  const addDefaultErrorHandling = useErrorHandler();
  const { mutate: createEmployeeRequest, ...rest } = useMutation(
    async (payload) => amApi.createEmployeeRequest(payload),
    addDefaultErrorHandling(options),
  );
  return { createEmployeeRequest, ...rest };
};

export const useCreateManagementRequest = (options) => {
  const addDefaultErrorHandling = useErrorHandler();
  const { mutate: createManagementRequest, ...rest } = useMutation(
    async (payload) => amApi.createManagementRequest(payload),
    addDefaultErrorHandling(options),
  );
  return { createManagementRequest, ...rest };
};

export const useCreateCategory = (options) => {
  const addDefaultErrorHandling = useErrorHandler();
  const { mutate: createCategory, ...rest } = useMutation(
    async (payload) => amApi.createCategory(payload),
    addDefaultErrorHandling(options),
  );
  return { createCategory, ...rest };
};

export const useDeleteCategory = (options) => {
  const addDefaultErrorHandling = useErrorHandler();
  const { mutate: deleteCategory, ...rest } = useMutation(
    async (id) => amApi.deleteCategory(id),
    addDefaultErrorHandling(options),
  );
  return { deleteCategory, ...rest };
};

export const useDeleteTraining = (options) => {
  const addDefaultErrorHandling = useErrorHandler();
  const { mutate: deleteTraining, ...rest } = useMutation(
    async (id) => amApi.deleteTraining(id),
    addDefaultErrorHandling(options),
  );
  return { deleteTraining, ...rest };
};

export const useUpdateCategory = (options) => {
  const addDefaultErrorHandling = useErrorHandler();
  const { mutate: updateCategory, ...rest } = useMutation(
    async ({ id, payload }) => amApi.updateCategory(id, payload),
    addDefaultErrorHandling(options),
  );
  return { updateCategory, ...rest };
};

export const useUpdateTraining = (options) => {
  const addDefaultErrorHandling = useErrorHandler();
  const { mutate: updateTraining, ...rest } = useMutation(
    async ({ id, payload }) => amApi.updateTraining(id, payload),
    addDefaultErrorHandling(options),
  );
  return { updateTraining, ...rest };
};

export const useCreateTimeOffPolicy = (options) => {
  const addDefaultErrorHandling = useErrorHandler();
  const { mutate: createPolicy, ...rest } = useMutation(
    async (payload) => amApi.createTimeOffPolicy(payload),
    addDefaultErrorHandling(options),
  );
  return { createPolicy, ...rest };
};

export const useCreateTimeOffCategory = (options) => {
  const addDefaultErrorHandling = useErrorHandler();
  const { mutate: createCategory, ...rest } = useMutation(
    async (payload) => amApi.createTimeOffCategory(payload),
    addDefaultErrorHandling(options),
  );
  return { createCategory, ...rest };
};

export const useDeleteTimeOffCategory = (options) => {
  const addDefaultErrorHandling = useErrorHandler();
  const { mutate: deleteCategory, ...rest } = useMutation(
    async (id) => amApi.deleteTimeOffCategory(id),
    addDefaultErrorHandling(options),
  );
  return { deleteCategory, ...rest };
};

export const useDeleteTimeOffPolicy = (options) => {
  const addDefaultErrorHandling = useErrorHandler();
  const { mutate: deletePolicy, ...rest } = useMutation(
    async (id) => amApi.deleteTimeOffPolicy(id),
    addDefaultErrorHandling(options),
  );
  return { deletePolicy, ...rest };
};

export const useUpdateTimeOffCategory = (options) => {
  const addDefaultErrorHandling = useErrorHandler();
  const { mutate: updateCategory, ...rest } = useMutation(
    async ({ id, payload }) => amApi.updateTimeOffCategory(id, payload),
    addDefaultErrorHandling(options),
  );
  return { updateCategory, ...rest };
};

export const useUpdateTimeOffPolicy = (options) => {
  const addDefaultErrorHandling = useErrorHandler();
  const { mutate: updatePolicy, ...rest } = useMutation(
    async ({ id, payload }) => amApi.updateTimeOffPolicy(id, payload),
    addDefaultErrorHandling(options),
  );
  return { updatePolicy, ...rest };
};
export const useAttachTimOffPolicy = (options) => {
  const addDefaultErrorHandling = useErrorHandler();
  const { mutate: attachPolicy, ...rest } = useMutation(
    async ({ employeeId, policyId, payload }) =>
      amApi.attachTimeOffPolicy(employeeId, policyId, payload),
    addDefaultErrorHandling(options),
  );
  return { attachPolicy, ...rest };
};

export const useDetachTimOffPolicy = (options) => {
  const addDefaultErrorHandling = useErrorHandler();
  const { mutate: detachPolicy, ...rest } = useMutation(
    async ({ employeeId, policyId }) =>
      amApi.detachTimeOffPolicy(employeeId, policyId),
    addDefaultErrorHandling(options),
  );
  return { detachPolicy, ...rest };
};

export const useCreateTimeOffRecord = (options) => {
  const addDefaultErrorHandling = useErrorHandler();
  const { mutate: createRecord, ...rest } = useMutation(
    async (payload) => amApi.createTimeOffRecord(payload),
    addDefaultErrorHandling(options),
  );
  return { createRecord, ...rest };
};

export const useDeleteTimeOffRecord = (canManageEmployee, options) => {
  const addDefaultErrorHandling = useErrorHandler();
  const { mutate: deleteRecord, ...rest } = useMutation(
    async (id) =>
      canManageEmployee
        ? amApi.manageDeleteTimeOffRecord(id)
        : amApi.employeeDeleteTimeOffRecord(id),
    addDefaultErrorHandling(options),
  );
  return { deleteRecord, ...rest };
};

export const useDeleteTimeOffRecords = (canManageEmployee, options) => {
  const addDefaultErrorHandling = useErrorHandler();
  const { mutate: deleteRecords, ...rest } = useMutation(
    async (payload) =>
      canManageEmployee
        ? amApi.deleteManageTimeOffRecords({ ...payload })
        : amApi.deleteEmployeeTimeOffRecords({ ...payload }),
    addDefaultErrorHandling(options),
  );
  return { deleteRecords, ...rest };
};

export const useUpdateTimeOffRecord = (options) => {
  const addDefaultErrorHandling = useErrorHandler();
  const { mutate: updateRecord, ...rest } = useMutation(
    async ({ id, payload }) => amApi.updateTimeOffRecord(id, payload),
    addDefaultErrorHandling(options),
  );
  return { updateRecord, ...rest };
};

export const useCreatePerformanceCycle = (options) => {
  const addDefaultErrorHandling = useErrorHandler();
  const { mutate: createCycle, ...rest } = useMutation(
    async (payload) => amApi.createPerformanceCycle(payload),
    addDefaultErrorHandling(options),
  );
  return { createCycle, ...rest };
};

export const useUpdatePerformanceCycle = (options) => {
  const addDefaultErrorHandling = useErrorHandler();
  const { mutate: updateCycle, ...rest } = useMutation(
    async ({ id, payload }) => amApi.updatePerformanceCycle(id, payload),
    addDefaultErrorHandling(options),
  );
  return { updateCycle, ...rest };
};

export const useDeletePerformanceCycle = (options) => {
  const addDefaultErrorHandling = useErrorHandler();
  const { mutate: deleteCycle, ...rest } = useMutation(
    async (id) => amApi.deletePerformanceCycle(id),
    addDefaultErrorHandling(options),
  );
  return { deleteCycle, ...rest };
};

export const useCreateSurvey = (options) => {
  const addDefaultErrorHandling = useErrorHandler();
  const { mutate: createSurvey, ...rest } = useMutation(
    async (payload) => amApi.createSurvey(payload),
    addDefaultErrorHandling(options),
  );
  return { createSurvey, ...rest };
};

export const useUpdateSurvey = (options) => {
  const addDefaultErrorHandling = useErrorHandler();
  const { mutate: updateSurvey, ...rest } = useMutation(
    async ({ id, payload }) => amApi.updateSurvey(id, payload),
    addDefaultErrorHandling(options),
  );
  return { updateSurvey, ...rest };
};

export const useDeleteSurvey = (options) => {
  const addDefaultErrorHandling = useErrorHandler();
  const { mutate: deleteSurvey, ...rest } = useMutation(
    async (id) => amApi.deleteSurvey(id),
    addDefaultErrorHandling(options),
  );
  return { deleteSurvey, ...rest };
};

export const useDeleteUserRecord = (options) => {
  const addDefaultErrorHandling = useErrorHandler();
  const { mutate: deleteUserRecord, ...rest } = useMutation(
    async (id) => amApi.deleteUserRecord(id),
    addDefaultErrorHandling(options),
  );
  return { deleteUserRecord, ...rest };
};

export const useCreateUserRecord = (options) => {
  const addDefaultErrorHandling = useErrorHandler();
  const { mutate: createUserRecord, ...rest } = useMutation(
    async (payload) => amApi.createUserRecord(payload),
    addDefaultErrorHandling(options),
  );
  return { createUserRecord, ...rest };
};

export const useUpdateUserRecord = (options) => {
  const addDefaultErrorHandling = useErrorHandler();
  const { mutate: updateUserRecord, ...rest } = useMutation(
    async ({ recordId, payload }) => amApi.updateUserRecord(recordId, payload),
    addDefaultErrorHandling(options),
  );
  return { updateUserRecord, ...rest };
};

export const useCreateLocation = (options) => {
  const addDefaultErrorHandling = useErrorHandler();
  const { mutate: createLocation, ...rest } = useMutation(
    async (payload) => amApi.createLocation(payload),
    addDefaultErrorHandling(options),
  );
  return { createLocation, ...rest };
};

export const useUpdateLocation = (options) => {
  const addDefaultErrorHandling = useErrorHandler();
  const { mutate: updateLocation, ...rest } = useMutation(
    async ({ id, payload }) => amApi.updateLocation(id, payload),
    addDefaultErrorHandling(options),
  );
  return { updateLocation, ...rest };
};

export const useDeleteLocation = (options) => {
  const addDefaultErrorHandling = useErrorHandler();
  const { mutate: deleteLocation, ...rest } = useMutation(
    async (id) => amApi.deleteLocation(id),
    addDefaultErrorHandling(options),
  );
  return { deleteLocation, ...rest };
};

export const useCreateJobTitle = (options) => {
  const addDefaultErrorHandling = useErrorHandler();
  const { mutate: createJobTitle, ...rest } = useMutation(
    async (payload) => amApi.createJobTitle(payload),
    addDefaultErrorHandling(options),
  );
  return { createJobTitle, ...rest };
};

export const useUpdateJobTitle = (options) => {
  const addDefaultErrorHandling = useErrorHandler();
  const { mutate: updateJobTitle, ...rest } = useMutation(
    async ({ id, payload }) => amApi.updateJobTitle(id, payload),
    addDefaultErrorHandling(options),
  );
  return { updateJobTitle, ...rest };
};

export const useDeleteJobTitle = (options) => {
  const addDefaultErrorHandling = useErrorHandler();
  const { mutate: deleteJobTitle, ...rest } = useMutation(
    async (id) => amApi.deleteJobTitle(id),
    addDefaultErrorHandling(options),
  );
  return { deleteJobTitle, ...rest };
};

export const useCreateDepartment = (options) => {
  const addDefaultErrorHandling = useErrorHandler();
  const { mutate: createDepartment, ...rest } = useMutation(
    async (payload) => amApi.createDepartment(payload),
    addDefaultErrorHandling(options),
  );
  return { createDepartment, ...rest };
};

export const useUpdateDepartment = (options) => {
  const addDefaultErrorHandling = useErrorHandler();
  const { mutate: updateDepartment, ...rest } = useMutation(
    async ({ id, payload }) => amApi.updateDepartment(id, payload),
    addDefaultErrorHandling(options),
  );
  return { updateDepartment, ...rest };
};

export const useDeleteDepartment = (options) => {
  const addDefaultErrorHandling = useErrorHandler();
  const { mutate: deleteDepartment, ...rest } = useMutation(
    async (id) => amApi.deleteDepartment(id),
    addDefaultErrorHandling(options),
  );
  return { deleteDepartment, ...rest };
};

export const useCreateDivision = (options) => {
  const addDefaultErrorHandling = useErrorHandler();
  const { mutate: createDivision, ...rest } = useMutation(
    async (payload) => amApi.createDivision(payload),
    addDefaultErrorHandling(options),
  );
  return { createDivision, ...rest };
};

export const useUpdateDivision = (options) => {
  const addDefaultErrorHandling = useErrorHandler();
  const { mutate: updateDivision, ...rest } = useMutation(
    async ({ id, payload }) => amApi.updateDivision(id, payload),
    addDefaultErrorHandling(options),
  );
  return { updateDivision, ...rest };
};

export const useDeleteDivision = (options) => {
  const addDefaultErrorHandling = useErrorHandler();
  const { mutate: deleteDivision, ...rest } = useMutation(
    async (id) => amApi.deleteDivision(id),
    addDefaultErrorHandling(options),
  );
  return { deleteDivision, ...rest };
};

export const useCreateJobInformation = (options) => {
  const addDefaultErrorHandling = useErrorHandler();
  const { mutate: createJobInformation, ...rest } = useMutation(
    async ({ employeeId, ...payload }) =>
      amApi.createJobInformation(employeeId, payload),
    addDefaultErrorHandling(options),
  );
  return { createJobInformation, ...rest };
};

export const useDeleteJobInformation = (options) => {
  const addDefaultErrorHandling = useErrorHandler();
  const { mutate: deleteJobInformation, ...rest } = useMutation(
    async ({ employeeId, ...payload }) =>
      amApi.deleteJobInformation(employeeId, payload),
    addDefaultErrorHandling(options),
  );
  return { deleteJobInformation, ...rest };
};

export const useUpdateJobInformation = (options) => {
  const addDefaultErrorHandling = useErrorHandler();
  const { mutate: updateJobInformation, ...rest } = useMutation(
    async ({ employeeId, ...payload }) =>
      amApi.updateJobInformation(employeeId, payload),
    addDefaultErrorHandling(options),
  );
  return { updateJobInformation, ...rest };
};

export const useCreateTaskList = (options) => {
  const addDefaultErrorHandling = useErrorHandler();
  const { mutate: createTaskList, ...rest } = useMutation(
    async ({ taskType, name }) =>
      amApi.createTaskList({ task_type: taskType, name }),
    addDefaultErrorHandling(options),
  );
  return { createTaskList, ...rest };
};

export const useUpdateTaskList = (options) => {
  const addDefaultErrorHandling = useErrorHandler();
  const { mutate: updateTaskList, ...rest } = useMutation(
    async ({ id, name, taskType }) =>
      amApi.updateTaskList(id, { task_type: taskType, name }),
    addDefaultErrorHandling(options),
  );
  return { updateTaskList, ...rest };
};

export const useDeleteTaskList = (options) => {
  const addDefaultErrorHandling = useErrorHandler();
  const { mutate: deleteTaskList, ...rest } = useMutation(
    async (id) => amApi.deleteTaskList(id),
    addDefaultErrorHandling(options),
  );
  return { deleteTaskList, ...rest };
};

export const useCreateTask = (options) => {
  const addDefaultErrorHandling = useErrorHandler();
  const { mutate: createTask, ...rest } = useMutation(
    async (payload) => amApi.createTask(payload),
    addDefaultErrorHandling(options),
  );
  return { createTask, ...rest };
};
export const useUpdateTask = (options) => {
  const addDefaultErrorHandling = useErrorHandler();
  const { mutate: updateTask, ...rest } = useMutation(
    async ({ taskId, payload }) => amApi.updateTask(taskId, payload),
    addDefaultErrorHandling(options),
  );
  return { updateTask, ...rest };
};

export const useDeleteTask = (options) => {
  const addDefaultErrorHandling = useErrorHandler();
  const { mutate: deleteTask, ...rest } = useMutation(
    async (id) => amApi.deleteTask(id),
    addDefaultErrorHandling(options),
  );
  return { deleteTask, ...rest };
};

export const useCopyEmployeeTasks = ({ id, ...payload }, options) => {
  const addDefaultErrorHandling = useErrorHandler();
  const { mutate: copyEmployeeTasks, ...rest } = useMutation(
    async () => amApi.copyEmployeeTasks(id, payload),
    addDefaultErrorHandling(options),
  );
  return { copyEmployeeTasks, ...rest };
};

export const useCreateEmployeeTask = (options) => {
  const addDefaultErrorHandling = useErrorHandler();
  const { mutate: createEmployeeTask, ...rest } = useMutation(
    async (payload) => amApi.createEmployeeTask(payload),
    addDefaultErrorHandling(options),
  );
  return { createEmployeeTask, ...rest };
};

export const useUpdateEmployeeTask = (options) => {
  const addDefaultErrorHandling = useErrorHandler();
  const { mutate: updateEmployeeTask, ...rest } = useMutation(
    async ({ id, ...payload }) => amApi.updateEmployeeTask(id, payload),
    addDefaultErrorHandling(options),
  );
  return { updateEmployeeTask, ...rest };
};

export const useDeleteEmployeeTask = (options) => {
  const addDefaultErrorHandling = useErrorHandler();
  const { mutate: deleteEmployeeTask, ...rest } = useMutation(
    async (id) => amApi.deleteEmployeeTask(id),
    addDefaultErrorHandling(options),
  );
  return { deleteEmployeeTask, ...rest };
};

export const useMarkAsCompletedTask = (options) => {
  const addDefaultErrorHandling = useErrorHandler();
  const { mutate: markAsCompletedTask, ...rest } = useMutation(
    async ({ id, ...payload }) => amApi.markAsCompletedTask(id, payload),
    addDefaultErrorHandling(options),
  );
  return { markAsCompletedTask, ...rest };
};

export const useCreateCandidateSource = (options) => {
  const addDefaultErrorHandling = useErrorHandler();
  const { mutate: createCandidateSource, ...rest } = useMutation(
    async (payload) => amApi.createCandidateSource(payload),
    addDefaultErrorHandling(options),
  );
  return { createCandidateSource, ...rest };
};

export const useDeleteCandidateSource = (options) => {
  const addDefaultErrorHandling = useErrorHandler();
  const { mutate: deleteCandidateSource, ...rest } = useMutation(
    async (id) => amApi.deleteCandidateSource(id),
    addDefaultErrorHandling(options),
  );
  return { deleteCandidateSource, ...rest };
};

export const useCreateCandidateStatus = (options) => {
  const addDefaultErrorHandling = useErrorHandler();
  const { mutate: createCandidateStatus, ...rest } = useMutation(
    async (payload) => amApi.createCandidateStatus(payload),
    addDefaultErrorHandling(options),
  );
  return { createCandidateStatus, ...rest };
};

export const useDeleteCandidateStatus = (options) => {
  const addDefaultErrorHandling = useErrorHandler();
  const { mutate: deleteCandidateStatus, ...rest } = useMutation(
    async (id) => amApi.deleteCandidateStatus(id),
    addDefaultErrorHandling(options),
  );
  return { deleteCandidateStatus, ...rest };
};

export const useUpdateCandidateStatus = (options) => {
  const addDefaultErrorHandling = useErrorHandler();
  const { mutate: updateCandidateStatus, ...rest } = useMutation(
    async ({ id, ...payload }) => amApi.updateCandidateStatus(id, payload),
    addDefaultErrorHandling(options),
  );
  return { updateCandidateStatus, ...rest };
};

export const useUpdateCandidateStatusOrder = (options) => {
  const addDefaultErrorHandling = useErrorHandler();
  const { mutate: updateCandidateStatusOrder, ...rest } = useMutation(
    async (payload) => amApi.updateCandidateStatusOrder(payload),
    addDefaultErrorHandling(options),
  );
  return { updateCandidateStatusOrder, ...rest };
};

export const useCreateEmailTemplate = (options) => {
  const addDefaultErrorHandling = useErrorHandler();
  const { mutate: createEmailTemplate, ...rest } = useMutation(
    async (payload) => amApi.createEmailTemplate(payload),
    addDefaultErrorHandling(options),
  );
  return { createEmailTemplate, ...rest };
};

export const useUpdateEmailTemplate = (options) => {
  const addDefaultErrorHandling = useErrorHandler();
  const { mutate: updateEmailTemplate, ...rest } = useMutation(
    async ({ id, ...payload }) => amApi.updateEmailTemplate(id, payload),
    addDefaultErrorHandling(options),
  );
  return { updateEmailTemplate, ...rest };
};

export const useDeleteEmailTemplate = (options) => {
  const addDefaultErrorHandling = useErrorHandler();
  const { mutate: deleteEmailTemplate, ...rest } = useMutation(
    async (id) => amApi.deleteEmailTemplate(id),
    addDefaultErrorHandling(options),
  );
  return { deleteEmailTemplate, ...rest };
};

export const useCreateCandidateEmail = (options) => {
  const addDefaultErrorHandling = useErrorHandler();
  const { mutate: createCandidateEmail, ...rest } = useMutation(
    async ({ id, ...payload }) => amApi.createCandidateEmail(id, payload),
    addDefaultErrorHandling(options),
  );
  return { createCandidateEmail, ...rest };
};

export const useCreateOfferTemplate = (options) => {
  const addDefaultErrorHandling = useErrorHandler();
  const { mutate: createOfferTemplate, ...rest } = useMutation(
    async (payload) => amApi.createOfferTemplate(payload),
    addDefaultErrorHandling(options),
  );
  return { createOfferTemplate, ...rest };
};

export const useUpdateOfferTemplate = (options) => {
  const addDefaultErrorHandling = useErrorHandler();
  const { mutate: updateOfferTemplate, ...rest } = useMutation(
    async ({ id, ...payload }) => amApi.updateOfferTemplate(id, payload),
    addDefaultErrorHandling(options),
  );
  return { updateOfferTemplate, ...rest };
};

export const useDeleteOfferTemplate = (options) => {
  const addDefaultErrorHandling = useErrorHandler();
  const { mutate: deleteOfferTemplate, ...rest } = useMutation(
    async (id) => amApi.deleteOfferTemplate(id),
    addDefaultErrorHandling(options),
  );
  return { deleteOfferTemplate, ...rest };
};

export const useCreateCandidateOffer = (options) => {
  const addDefaultErrorHandling = useErrorHandler();
  const { mutate: createCandidateOffer, ...rest } = useMutation(
    async ({ id, ...payload }) => amApi.createCandidateOffer(id, payload),
    addDefaultErrorHandling(options),
  );
  return { createCandidateOffer, ...rest };
};

export const useCancelCandidateOffer = (options) => {
  const addDefaultErrorHandling = useErrorHandler();
  const { mutate: cancelCandidateOffer, ...rest } = useMutation(
    async (offerId) => amApi.cancelCandidateOffer(offerId),
    addDefaultErrorHandling(options),
  );
  return { cancelCandidateOffer, ...rest };
};

export const useUpdateCandidateOffer = (options) => {
  const addDefaultErrorHandling = useErrorHandler();
  const { mutate: updateCandidateOffer, ...rest } = useMutation(
    async ({ id, ...payload }) => amApi.updateCandidateOffer(id, payload),
    addDefaultErrorHandling(options),
  );
  return { updateCandidateOffer, ...rest };
};

export const useSignCandidateOffer = (options) => {
  const addDefaultErrorHandling = useErrorHandler();
  const { mutate: signCandidateOffer, ...rest } = useMutation(
    async (hash) => amApi.signCandidateOffer(hash),
    addDefaultErrorHandling(options),
  );
  return { signCandidateOffer, ...rest };
};

export const useApproveRequest = (options) => {
  const addDefaultErrorHandling = useErrorHandler();
  const { mutate: approveRequest, ...rest } = useMutation(
    async (requestId) => amApi.approveRequest(requestId),
    addDefaultErrorHandling(options),
  );
  return { approveRequest, ...rest };
};

export const useDenyRequest = (options) => {
  const addDefaultErrorHandling = useErrorHandler();
  const { mutate: denyRequest, ...rest } = useMutation(
    async (requestId) => amApi.denyRequest(requestId),
    addDefaultErrorHandling(options),
  );
  return { denyRequest, ...rest };
};

export const useDeleteRequest = (options) => {
  const addDefaultErrorHandling = useErrorHandler();
  const { mutate: deleteRequest, ...rest } = useMutation(
    async (requestId) => amApi.deleteRequest(requestId),
    addDefaultErrorHandling(options),
  );
  return { deleteRequest, ...rest };
};

export const useUpdateRequest = (options) => {
  const addDefaultErrorHandling = useErrorHandler();
  const { mutate: updateRequest, ...rest } = useMutation(
    async ({ requestId, ...payload }) =>
      amApi.updateRequest(requestId, payload),
    addDefaultErrorHandling(options),
  );
  return { updateRequest, ...rest };
};

export const useCompleteSurveyRequest = (options) => {
  const addDefaultErrorHandling = useErrorHandler();
  const { mutate: completeSurveyRequest, ...rest } = useMutation(
    async ({ requestId, ...payload }) =>
      amApi.completeSurveyRequest(requestId, payload),
    addDefaultErrorHandling(options),
  );
  return { completeSurveyRequest, ...rest };
};

export const useSendResetPasswordLink = (options) => {
  const addDefaultErrorHandling = useErrorHandler();
  const { mutate: sendPasswordResetLink, ...rest } = useMutation(
    async (email) => amApi.sendPasswordResetLink(email),
    addDefaultErrorHandling(options),
  );
  return { sendPasswordResetLink, ...rest };
};

export const useResetPassword = (options) => {
  const addDefaultErrorHandling = useErrorHandler();
  const { mutate: resetPassword, ...rest } = useMutation(
    async ({ hash, ...payload }) => amApi.resetPassword(hash, payload),
    addDefaultErrorHandling(options),
  );
  return { resetPassword, ...rest };
};

export const useUpdateCurrentUser = (options) => {
  const addDefaultErrorHandling = useErrorHandler();
  const { mutate: updateCurrentUser, ...rest } = useMutation(
    async (payload) => amApi.updateCurrentUser(payload),
    addDefaultErrorHandling(options),
  );
  return { updateCurrentUser, ...rest };
};

export const useExportHeadCountList = (params, options) => {
  const addDefaultErrorHandling = useErrorHandler();
  const { mutate: exportHeadCountList, ...rest } = useMutation(
    async () => amApi.exportHeadCountList(params),
    addDefaultErrorHandling(options),
  );

  return {
    exportHeadCountList,
    ...rest,
  };
};

export const useUpdateEmployeeAvatar = (options) => {
  const addDefaultErrorHandling = useErrorHandler();
  const { mutate: updateEmployeeAvatar, ...rest } = useMutation(
    async ({ employeeId, ...params }) =>
      amApi.updateEmployeeAvatar(employeeId, params),
    addDefaultErrorHandling(options),
  );

  return {
    updateEmployeeAvatar,
    ...rest,
  };
};

export const useDeleteEmployeeAvatar = (options) => {
  const addDefaultErrorHandling = useErrorHandler();
  const { mutate: deleteEmployeeAvatar, ...rest } = useMutation(
    async (employeeId) => amApi.deleteEmployeeAvatar(employeeId),
    addDefaultErrorHandling(options),
  );

  return {
    deleteEmployeeAvatar,
    ...rest,
  };
};

export const useCreateEmployeeStatus = (options) => {
  const addDefaultErrorHandling = useErrorHandler();
  const { mutate: createEmployeeStatus, ...rest } = useMutation(
    async (payload) => amApi.createEmployeeStatus(payload),
    addDefaultErrorHandling(options),
  );

  return {
    createEmployeeStatus,
    ...rest,
  };
};

export const useDeleteEmployeeStatus = (options) => {
  const addDefaultErrorHandling = useErrorHandler();
  const { mutate: deleteEmployeeStatus, ...rest } = useMutation(
    async (statusId) => amApi.deleteEmployeeStatus(statusId),
    addDefaultErrorHandling(options),
  );

  return {
    deleteEmployeeStatus,
    ...rest,
  };
};

export const useUpdateEmployeeStatus = (options) => {
  const addDefaultErrorHandling = useErrorHandler();
  const { mutate: updateEmployeeStatus, ...rest } = useMutation(
    async ({ statusId, ...payload }) =>
      amApi.updateEmployeeStatus(statusId, payload),
    addDefaultErrorHandling(options),
  );

  return {
    updateEmployeeStatus,
    ...rest,
  };
};

export const useUpdateEmployeeLanguage = (options) => {
  const addDefaultErrorHandling = useErrorHandler();
  const { mutate: updateEmployeeLanguage, ...rest } = useMutation(
    async ({ langId, employeeId }) =>
      amApi.updateEmployeeLanguage(employeeId, { lang_id: langId }),
    addDefaultErrorHandling(options),
  );

  return {
    updateEmployeeLanguage,
    ...rest,
  };
};

export const useUpdateEmployeeStatusRecord = (options) => {
  const addDefaultErrorHandling = useErrorHandler();
  const { mutate: updateEmployeeStatusRecord, ...rest } = useMutation(
    async ({ recordId, ...payload }) =>
      amApi.updateEmployeeStatusRecord(recordId, payload),
    addDefaultErrorHandling(options),
  );

  return {
    updateEmployeeStatusRecord,
    ...rest,
  };
};

export const useDeleteEmployeeStatusRecord = (options) => {
  const addDefaultErrorHandling = useErrorHandler();
  const { mutate: deleteEmployeeStatusRecord, ...rest } = useMutation(
    async (recordId) => amApi.deleteEmployeeStatusRecord(recordId),
    addDefaultErrorHandling(options),
  );

  return {
    deleteEmployeeStatusRecord,
    ...rest,
  };
};

export const useCreateEmployeeStatusRecord = (options) => {
  const addDefaultErrorHandling = useErrorHandler();
  const { mutate: createEmployeeStatusRecord, ...rest } = useMutation(
    async (payload) => amApi.createEmployeeStatusRecord(payload),
    addDefaultErrorHandling(options),
  );

  return {
    createEmployeeStatusRecord,
    ...rest,
  };
};

export const useCreateReason = (options) => {
  const addDefaultErrorHandling = useErrorHandler();
  const { mutate: createReason, ...rest } = useMutation(
    async (payload) => amApi.createReason(payload),
    addDefaultErrorHandling(options),
  );

  return {
    createReason,
    ...rest,
  };
};

export const useDeleteReason = (options) => {
  const addDefaultErrorHandling = useErrorHandler();
  const { mutate: deleteReason, ...rest } = useMutation(
    async (reasonId) => amApi.deleteReason(reasonId),
    addDefaultErrorHandling(options),
  );

  return {
    deleteReason,
    ...rest,
  };
};

export const useUpdateReason = (options) => {
  const addDefaultErrorHandling = useErrorHandler();
  const { mutate: updateReason, ...rest } = useMutation(
    async ({ reasonId, ...payload }) => amApi.updateReason(reasonId, payload),
    addDefaultErrorHandling(options),
  );

  return {
    updateReason,
    ...rest,
  };
};

export const useCreatePaySchedule = (options) => {
  const addDefaultErrorHandling = useErrorHandler();
  const { mutate: createPaySchedule, ...rest } = useMutation(
    async (payload) => amApi.createPaySchedule(payload),
    addDefaultErrorHandling(options),
  );

  return {
    createPaySchedule,
    ...rest,
  };
};

export const useUpdatePaySchedule = (options) => {
  const addDefaultErrorHandling = useErrorHandler();
  const { mutate: updatePaySchedule, ...rest } = useMutation(
    async ({ payScheduleId, ...payload }) =>
      amApi.updatePaySchedule(payScheduleId, payload),
    addDefaultErrorHandling(options),
  );

  return {
    updatePaySchedule,
    ...rest,
  };
};

export const useDeletePaySchedule = (options) => {
  const addDefaultErrorHandling = useErrorHandler();
  const { mutate: deletePaySchedule, ...rest } = useMutation(
    async (payScheduleId) => amApi.deletePaySchedule(payScheduleId),
    addDefaultErrorHandling(options),
  );

  return {
    deletePaySchedule,
    ...rest,
  };
};

export const useCreateEmployeeCompensation = (options) => {
  const addDefaultErrorHandling = useErrorHandler();
  const { mutate: createEmployeeCompensation, ...rest } = useMutation(
    async (payload) => amApi.createEmployeeCompensation(payload),
    addDefaultErrorHandling(options),
  );

  return {
    createEmployeeCompensation,
    ...rest,
  };
};

export const useUpdateEmployeeCompensation = (options) => {
  const addDefaultErrorHandling = useErrorHandler();
  const { mutate: updateEmployeeCompensation, ...rest } = useMutation(
    async ({ compensationId, ...payload }) =>
      amApi.updateEmployeeCompensation(compensationId, payload),
    addDefaultErrorHandling(options),
  );

  return {
    updateEmployeeCompensation,
    ...rest,
  };
};

export const useDeleteEmployeeCompensation = (options) => {
  const addDefaultErrorHandling = useErrorHandler();
  const { mutate: deleteEmployeeCompensation, ...rest } = useMutation(
    async (compensationId) => amApi.deleteEmployeeCompensation(compensationId),
    addDefaultErrorHandling(options),
  );

  return {
    deleteEmployeeCompensation,
    ...rest,
  };
};

export const useCreateEmployeeBonusRecords = (options) => {
  const addDefaultErrorHandling = useErrorHandler();
  const { mutate: createEmployeeBonusRecord, ...rest } = useMutation(
    async (payload) => amApi.createEmployeeBonusRecord(payload),
    addDefaultErrorHandling(options),
  );

  return {
    createEmployeeBonusRecord,
    ...rest,
  };
};

export const useUpdateEmployeeBonusRecord = (options) => {
  const addDefaultErrorHandling = useErrorHandler();
  const { mutate: updateEmployeeBonusRecord, ...rest } = useMutation(
    async ({ bonusRecordId, ...payload }) =>
      amApi.updateEmployeeBonusRecord(bonusRecordId, payload),
    addDefaultErrorHandling(options),
  );

  return {
    updateEmployeeBonusRecord,
    ...rest,
  };
};

export const useDeleteEmployeeBonusRecord = (options) => {
  const addDefaultErrorHandling = useErrorHandler();
  const { mutate: deleteEmployeeBonusRecord, ...rest } = useMutation(
    async (compensationId) => amApi.deleteEmployeeBonusRecord(compensationId),
    addDefaultErrorHandling(options),
  );

  return {
    deleteEmployeeBonusRecord,
    ...rest,
  };
};

export const useCreateAssetCategory = (options) => {
  const addDefaultErrorHandling = useErrorHandler();
  const { mutate: createAssetCategory, ...rest } = useMutation(
    async (payload) => amApi.createAssetCategory(payload),
    addDefaultErrorHandling(options),
  );

  return {
    createAssetCategory,
    ...rest,
  };
};

export const useUpdateAssetCategory = (options) => {
  const addDefaultErrorHandling = useErrorHandler();
  const { mutate: updateAssetCategory, ...rest } = useMutation(
    async ({ assetCategoryId, ...payload }) =>
      amApi.updateAssetCategory(assetCategoryId, payload),
    addDefaultErrorHandling(options),
  );

  return {
    updateAssetCategory,
    ...rest,
  };
};

export const useDeleteAssetCategory = (options) => {
  const addDefaultErrorHandling = useErrorHandler();
  const { mutate: deleteAssetCategory, ...rest } = useMutation(
    async (assetCategoryId) => amApi.deleteAssetCategory(assetCategoryId),
    addDefaultErrorHandling(options),
  );

  return {
    deleteAssetCategory,
    ...rest,
  };
};

export const useCreateEmployeeAsset = (options) => {
  const addDefaultErrorHandling = useErrorHandler();
  const { mutate: createEmployeeAsset, ...rest } = useMutation(
    async (payload) => amApi.createEmployeeAsset(payload),
    addDefaultErrorHandling(options),
  );

  return {
    createEmployeeAsset,
    ...rest,
  };
};

export const useUpdateEmployeeAsset = (options) => {
  const addDefaultErrorHandling = useErrorHandler();
  const { mutate: updateEmployeeAsset, ...rest } = useMutation(
    async ({ assetId, ...payload }) =>
      amApi.updateEmployeeAsset(assetId, payload),
    addDefaultErrorHandling(options),
  );

  return {
    updateEmployeeAsset,
    ...rest,
  };
};

export const useDeleteEmployeeAsset = (options) => {
  const addDefaultErrorHandling = useErrorHandler();
  const { mutate: deleteEmployeeAsset, ...rest } = useMutation(
    async (assetId) => amApi.deleteEmployeeAsset(assetId),
    addDefaultErrorHandling(options),
  );

  return {
    deleteEmployeeAsset,
    ...rest,
  };
};

export const useCreatePeerFeedback = (options) => {
  const addDefaultErrorHandling = useErrorHandler();
  const { mutate: createPeerFeedback, ...rest } = useMutation(
    async ({ requestId, ...payload }) =>
      amApi.createPeerFeedback(requestId, payload),
    addDefaultErrorHandling(options),
  );

  return {
    createPeerFeedback,
    ...rest,
  };
};

export const useOAuthLogin = (options) => {
  const addDefaultErrorHandling = useErrorHandler();
  const { mutate: oauthLogin, ...rest } = useMutation(
    async ({ provider, ...payload }) => amApi.oauthLogin(provider, payload),
    addDefaultErrorHandling(options),
  );

  return {
    oauthLogin,
    ...rest,
  };
};

export const useFormatGoogleCodeToToken = (options) => {
  const addDefaultErrorHandling = useErrorHandler();
  const { mutate: formatGoogleCodeToToken, ...rest } = useMutation(
    async (code) => amApi.formatGoogleCodeToToken(code),
    addDefaultErrorHandling(options),
  );

  return {
    formatGoogleCodeToToken,
    ...rest,
  };
};

export const useLoadGoogleUserData = (options) => {
  const addDefaultErrorHandling = useErrorHandler();
  const { mutate: loadGoogleUserData, ...rest } = useMutation(
    async (accessToken) => amApi.loadGoogleUserData(accessToken),
    addDefaultErrorHandling(options),
  );

  return {
    loadGoogleUserData,
    ...rest,
  };
};

export const useEnabledCompanyIntegration = (options) => {
  const addDefaultErrorHandling = useErrorHandler();
  const { mutate: enableCompanyIntegration, ...rest } = useMutation(
    async (integrationId) => amApi.enableCompanyIntegration(integrationId),
    addDefaultErrorHandling(options),
  );

  return {
    enableCompanyIntegration,
    ...rest,
  };
};

export const useDisableCompanyIntegration = (options) => {
  const addDefaultErrorHandling = useErrorHandler();
  const { mutate: disableCompanyIntegration, ...rest } = useMutation(
    async (integrationId) => amApi.disableCompanyIntegration(integrationId),
    addDefaultErrorHandling(options),
  );

  return {
    disableCompanyIntegration,
    ...rest,
  };
};

export const useUpdateFileStatus = (options) => {
  const addDefaultErrorHandling = useErrorHandler();
  const { mutate: updateFileStatus, ...rest } = useMutation(
    async ({ fileId, ...payload }) => amApi.updateFileStatus(fileId, payload),
    addDefaultErrorHandling(options),
  );

  return {
    updateFileStatus,
    ...rest,
  };
};

export const useSignRequest = (options) => {
  const addDefaultErrorHandling = useErrorHandler();
  const { mutate: signRequest, ...rest } = useMutation(
    async ({ requestId, ...payload }) => amApi.signRequest(requestId, payload),
    addDefaultErrorHandling(options),
  );

  return {
    signRequest,
    ...rest,
  };
};

export const useAddRoleToEmployees = (options) => {
  const addDefaultErrorHandling = useErrorHandler();
  const { mutate: addRolesToEmployee, ...rest } = useMutation(
    async (payload) => amApi.addRolesToEmployee(payload),
    addDefaultErrorHandling(options),
  );

  return {
    addRolesToEmployee,
    ...rest,
  };
};

export const useRemoveEmployeeRole = (options) => {
  const addDefaultErrorHandling = useErrorHandler();
  const { mutate: removeEmployeeRole, ...rest } = useMutation(
    async (payload) => amApi.removeEmployeeRole(payload),
    addDefaultErrorHandling(options),
  );

  return {
    removeEmployeeRole,
    ...rest,
  };
};

export const useCreateCompensationGroup = (options) => {
  const addDefaultErrorHandling = useErrorHandler();
  const { mutate: createGroup, ...rest } = useMutation(
    async (payload) => amApi.createCompensationGroup(payload),
    addDefaultErrorHandling(options),
  );

  return {
    createGroup,
    ...rest,
  };
};

export const useUpdateCompensationGroup = (options) => {
  const addDefaultErrorHandling = useErrorHandler();
  const { mutate: updateCompensationGroup, ...rest } = useMutation(
    async ({ groupId, ...payload }) =>
      amApi.updateCompensationGroup(groupId, payload),
    addDefaultErrorHandling(options),
  );

  return {
    updateCompensationGroup,
    ...rest,
  };
};

export const useDeleteCompensationGroup = (options) => {
  const addDefaultErrorHandling = useErrorHandler();
  const { mutate: deleteCompensationGroup, ...rest } = useMutation(
    async (groupId) => amApi.deleteCompensationGroup(groupId),
    addDefaultErrorHandling(options),
  );

  return {
    deleteCompensationGroup,
    ...rest,
  };
};

export const useUpdateCompensationBand = (options) => {
  const addDefaultErrorHandling = useErrorHandler();
  const { mutate: updateCompensationBand, ...rest } = useMutation(
    async ({ bandId, ...payload }) =>
      amApi.updateCompensationBand(bandId, payload),
    addDefaultErrorHandling(options),
  );

  return {
    updateCompensationBand,
    ...rest,
  };
};

export const useCreateCompensationBand = (options) => {
  const addDefaultErrorHandling = useErrorHandler();
  const { mutate: createCompensationBand, ...rest } = useMutation(
    async (payload) => amApi.createCompensationBand(payload),
    addDefaultErrorHandling(options),
  );

  return {
    createCompensationBand,
    ...rest,
  };
};

export const useDeleteCompensationBand = (options) => {
  const addDefaultErrorHandling = useErrorHandler();
  const { mutate: deleteCompensationBand, ...rest } = useMutation(
    async (payload) => amApi.deleteCompensationBand(payload),
    addDefaultErrorHandling(options),
  );

  return {
    deleteCompensationBand,
    ...rest,
  };
};

export const useDeleteHoliday = (options) => {
  const addDefaultErrorHandling = useErrorHandler();
  const { mutate: deleteHoliday, ...rest } = useMutation(
    async (payload) => amApi.deleteHoliday(payload),
    addDefaultErrorHandling(options),
  );

  return {
    deleteHoliday,
    ...rest,
  };
};

export const useCreateHoliday = (options) => {
  const addDefaultErrorHandling = useErrorHandler();
  const { mutate: createHoliday, ...rest } = useMutation(
    async (payload) => amApi.createHoliday(payload),
    addDefaultErrorHandling(options),
  );

  return {
    createHoliday,
    ...rest,
  };
};

export const useUpdateHoliday = (options) => {
  const addDefaultErrorHandling = useErrorHandler();
  const { mutate: updateHoliday, ...rest } = useMutation(
    async ({ holidayId, ...payload }) =>
      amApi.updateHoliday(holidayId, payload),
    addDefaultErrorHandling(options),
  );

  return {
    updateHoliday,
    ...rest,
  };
};

export const useCopyHolidays = (options) => {
  const addDefaultErrorHandling = useErrorHandler();
  const { mutate: copyHolidays, ...rest } = useMutation(
    async (payload) => amApi.copyHolidays(payload),
    addDefaultErrorHandling(options),
  );

  return {
    copyHolidays,
    ...rest,
  };
};

export const useAttachWidget = (options) => {
  const addDefaultErrorHandling = useErrorHandler();
  const { mutate: attachWidget, ...rest } = useMutation(
    async (payload) => amApi.attachWidget(payload),
    addDefaultErrorHandling(options),
  );

  return {
    attachWidget,
    ...rest,
  };
};

export const useDetachWidget = (options) => {
  const addDefaultErrorHandling = useErrorHandler();
  const { mutate: detachWidget, ...rest } = useMutation(
    async (widgetId) => amApi.detachWidget(widgetId),
    addDefaultErrorHandling(options),
  );

  return {
    detachWidget,
    ...rest,
  };
};

export const useUpdateWidgetPosition = (options) => {
  const addDefaultErrorHandling = useErrorHandler();
  const { mutate: updateWidgetPosition, ...rest } = useMutation(
    async (payload) => amApi.updateWidgetPosition(payload),
    addDefaultErrorHandling(options),
  );

  return {
    updateWidgetPosition,
    ...rest,
  };
};

export const useCreateLinkCategory = (options) => {
  const addDefaultErrorHandling = useErrorHandler();
  const { mutate: createLinkCategory, ...rest } = useMutation(
    async (payload) => amApi.createLinkCategory(payload),
    addDefaultErrorHandling(options),
  );

  return {
    createLinkCategory,
    ...rest,
  };
};

export const useCreateCompanyLink = (options) => {
  const addDefaultErrorHandling = useErrorHandler();
  const { mutate: createCompanyLink, ...rest } = useMutation(
    async (payload) => amApi.createCompanyLink(payload),
    addDefaultErrorHandling(options),
  );

  return {
    createCompanyLink,
    ...rest,
  };
};

export const useReorderCompanyLinks = (options) => {
  const addDefaultErrorHandling = useErrorHandler();
  const { mutate: reorderCompanyLinks, ...rest } = useMutation(
    async (payload) => amApi.reorderCompanyLinks(payload),
    addDefaultErrorHandling(options),
  );

  return {
    reorderCompanyLinks,
    ...rest,
  };
};

export const useUpdateCompanyLink = (options) => {
  const addDefaultErrorHandling = useErrorHandler();
  const { mutate: updateCompanyLink, ...rest } = useMutation(
    async ({ linkId, ...payload }) => amApi.updateCompanyLink(linkId, payload),
    addDefaultErrorHandling(options),
  );

  return {
    updateCompanyLink,
    ...rest,
  };
};

export const useDeleteCompanyLink = (options) => {
  const addDefaultErrorHandling = useErrorHandler();
  const { mutate: deleteCompanyLink, ...rest } = useMutation(
    async (linkId) => amApi.deleteCompanyLink(linkId),
    addDefaultErrorHandling(options),
  );

  return {
    deleteCompanyLink,
    ...rest,
  };
};

export const useDeleteCompanyLinkCategory = (options) => {
  const addDefaultErrorHandling = useErrorHandler();
  const { mutate: deleteCompanyLinkCategory, ...rest } = useMutation(
    async (categoryId) => amApi.deleteCompanyLinkCategory(categoryId),
    addDefaultErrorHandling(options),
  );

  return {
    deleteCompanyLinkCategory,
    ...rest,
  };
};

export const useUpdateCompanyLinkCategory = (options) => {
  const addDefaultErrorHandling = useErrorHandler();
  const { mutate: updateCompanyLinkCategory, ...rest } = useMutation(
    async ({ categoryId, ...payload }) =>
      amApi.updateCompanyLinkCategory(categoryId, payload),
    addDefaultErrorHandling(options),
  );

  return {
    updateCompanyLinkCategory,
    ...rest,
  };
};

export const useUpdateCategoriesOrder = (options) => {
  const addDefaultErrorHandling = useErrorHandler();
  const { mutate: updateCategoriesOrder, ...rest } = useMutation(
    async (payload) => amApi.updateCategoriesOrder(payload),
    addDefaultErrorHandling(options),
  );

  return {
    updateCategoriesOrder,
    ...rest,
  };
};

export const useCreateAnnounce = (options) => {
  const addDefaultErrorHandling = useErrorHandler();
  const { mutate: createAnnounce, ...rest } = useMutation(
    async (payload) => amApi.createAnnounce(payload),
    addDefaultErrorHandling(options),
  );

  return {
    createAnnounce,
    ...rest,
  };
};

export const useDeleteAnnouncement = (options) => {
  const addDefaultErrorHandling = useErrorHandler();
  const { mutate: deleteAnnouncement, ...rest } = useMutation(
    async (announcementId) => amApi.deleteAnnouncement(announcementId),
    addDefaultErrorHandling(options),
  );

  return {
    deleteAnnouncement,
    ...rest,
  };
};

export const useUpdateAnnouncement = (options) => {
  const addDefaultErrorHandling = useErrorHandler();
  const { mutate: updateAnnouncement, ...rest } = useMutation(
    async ({ announcementId, ...payload }) =>
      amApi.updateAnnouncement(announcementId, payload),
    addDefaultErrorHandling(options),
  );

  return {
    updateAnnouncement,
    ...rest,
  };
};

export const useDeleteJobOpeningWhitelistItem = (options) => {
  const addDefaultErrorHandling = useErrorHandler();
  const { mutate: deleteJobOpeningWhitelistItem, ...rest } = useMutation(
    async (recordId) => amApi.deleteJobOpeningWhitelistItem(recordId),
    addDefaultErrorHandling(options),
  );

  return {
    deleteJobOpeningWhitelistItem,
    ...rest,
  };
};

export const useCreateJobOpeningWhiteListItem = (options) => {
  const addDefaultErrorHandling = useErrorHandler();
  const { mutate: createJobOpeningWhiteListItem, ...rest } = useMutation(
    async (payload) => amApi.createJobOpeningWhiteListItem(payload),
    addDefaultErrorHandling(options),
  );

  return {
    createJobOpeningWhiteListItem,
    ...rest,
  };
};

export const useUpdateJobOpeningWhiteListItem = (options) => {
  const addDefaultErrorHandling = useErrorHandler();
  const { mutate: updateJobOpeningListItem, ...rest } = useMutation(
    async ({ recordId, ...payload }) =>
      amApi.updateJobOpeningListItem(recordId, payload),
    addDefaultErrorHandling(options),
  );

  return {
    updateJobOpeningListItem,
    ...rest,
  };
};

export const useApplyJobApplication = (options) => {
  const addDefaultErrorHandling = useErrorHandler();
  const { mutate: applyJobApplication, ...rest } = useMutation(
    async (payload) => amApi.applyJobApplication(payload),
    addDefaultErrorHandling(options),
  );
  return { applyJobApplication, ...rest };
};

export const useUpdateRequestStatuses = (
  { requestId, canManageEmployee },
  options,
) => {
  const addDefaultErrorHandling = useErrorHandler();
  const { mutate: updateRequestStatus, ...rest } = useMutation(
    async (payload) =>
      amApi.updateRequestStatus({ requestId, canManageEmployee }, payload),
    addDefaultErrorHandling(options),
  );
  return { updateRequestStatus, ...rest };
};

export const useCreateSatisfactionSurvey = (options) => {
  const addDefaultErrorHandling = useErrorHandler();
  const { mutate: createSatisfactionSurvey, ...rest } = useMutation(
    async (payload) => amApi.createSatisfactionSurvey(payload),
    addDefaultErrorHandling(options),
  );
  return { createSatisfactionSurvey, ...rest };
};

export const useUpdateSatisfactionSurvey = (options) => {
  const addDefaultErrorHandling = useErrorHandler();
  const { mutate: updateSatisfactionSurvey, ...rest } = useMutation(
    async ({ surveyId, ...payload }) =>
      amApi.updateSatisfactionSurvey(surveyId, payload),
    addDefaultErrorHandling(options),
  );
  return { updateSatisfactionSurvey, ...rest };
};

export const useSubmitSatisfactionSurvey = (options) => {
  const addDefaultErrorHandling = useErrorHandler();
  const { mutate: submitSatisfactionSurvey, ...rest } = useMutation(
    async ({ responseHash, ...payload }) =>
      amApi.submitSatisfactionSurvey(responseHash, payload),
    addDefaultErrorHandling(options),
  );
  return { submitSatisfactionSurvey, ...rest };
};

export const themes = {
  dark: 'dark',
  light: 'light',
};

export const resolver = () => ({
  light: {
    '--white': '#fafafa',
  },
  dark: {
    '--white': '#1f1f1f',
    '--body': '#1f1f1f',
    '--mantine-color-white': 'white',
    '--mantine-color-gray-3': 'var(--mantine-color-dark-4)',
    '--mantine-color-text': 'white',
    '--mantine-color-blue-6': '#0078d4',
    '--mantine-color-blue-filled': '#0078d4',
    '--mantine-color-gray-0': 'var(--mantine-color-dark-8)',
    '--mantine-color-gray-1': 'var(--mantine-color-dark-6)',
    '--mantine-color-gray-2': 'var(--mantine-color-dark-3)',
    '--mantine-color-gray-4': 'var(--mantine-color-dark-3)',
    '--white-dark': 'var(--mantine-color-dark-6)',
    '--dark-white':
      'light-dark(var(--mantine-color-gray-7), var(--mantine-color-dark-0))',
    '--mantine-color-blue-1': 'var(--mantine-color-blue-8)',
  },
});

import { MIME_TYPES } from '@mantine/dropzone';

export const avoidRedirectRoots = [
  '/login',
  '/forgot',
  '/offer-preview',
  '/candidate-offer',
  '/reset-password',
  '/choose-password',
];

export const frequencyValues = [
  { value: '0', label: 'One Time' },
  { value: '3', label: 'Every 3 Months' },
  { value: '6', label: 'Two times a year' },
  { value: '12', label: 'Every year' },
  { value: '18', label: 'Once in 18 months' },
  { value: '24', label: 'Every 2 years' },
];

export const questionTypes = [
  { value: 'text', label: 'Text' },
  { value: 'select', label: 'Select' },
  { value: 'options', label: 'Options' },
];

export const surveys = {
  assessment: { value: '10', label: 'Performance Assessment' },
  upwardFeedback: { value: '11', label: 'Upward Feedback' },
  manager: { value: '12', label: 'Manager Assessment' },
  peerFeedback: { value: '13', label: 'Peer Feedback' },
  candidate: { value: '20', label: 'Candidate' },
  other: { value: '100', label: 'Other' },
};

export const surveyTypes = Object.values(surveys);

export const surveyStatuses = [
  { value: '0', label: 'Draft' },
  { value: '1', label: 'Active' },
  { value: '2', label: 'Archived' },
];

export const surveyStatusesObj = {
  Draft: surveyStatuses[0].value,
  Active: surveyStatuses[1].value,
  Archived: surveyStatuses[2].value,
};

export const surveysSelectResponses = {
  'Strongly Disagree': 1,
  Disagree: 2,
  Neutral: 3,
  Agree: 4,
  'Strongly Agree': 5,
};

export const performanceCycleStatuses = [
  { value: '0', label: 'Draft' },
  { value: '1', label: 'Active' },
  { value: '2', label: 'Disabled' },
];

export const requestTypes = [
  { value: 'assets', label: 'Asset Request' },
  { value: 'compensation', label: 'Compensation' },
  { value: 'status', label: 'Employment Status' },
  { value: 'jobInfo', label: 'Job Information' },
  { value: 'promotion', label: 'Promotion' },
];

export const boardingListTypes = {
  onboarding: 1,
  offboarding: 0,
};

export const variablePattern = /\{([^}]+)\}/;
export const variablePatternG = /\{([^}]+)\}/g;

export const offerStatuses = {
  Sent: 1,
  Signed: 3,
  Archived: 4,
};

export const requestsCategories = {
  'Time Off': 'timeOffId',
  Performance: 'performanceId',
  Signature: 'signatureId',
  Asset: 'assetId',
  Compensation: 'compensationId',
  EmploymentStatus: 'employmentStatusId',
  JobInformation: 'jobInformationId',
  Promotion: 'promotionId',
};

export const requestStatuses = {
  Pending: 'pending',
  Approved: 'approved',
  Denied: 'denied',
  Deleted: 'deleted',
  Completed: 'completed',
  Signed: 'signed',
};

export const requestStatusesVal = {
  Pending: 0,
  Approved: 10,
  Denied: 20,
  Deleted: 30,
  Completed: 40,
};

export const requestPerformanceTypes = {
  choice: 'choiceable',
};

export const performanceCycleDateTypes = {
  fixedDate: 0,
  hireDate: 1,
};

export const startAssessment = Array.from({ length: 4 })
  .map((val, index) => ({
    value: `${index + 1}`,
    label: `${index + 1} ${
      index > 1 ? 'months' : 'month'
    } after employee's hire date`,
  }))
  .concat({ value: '12', label: "One year after employee's hire date" });

export const celebrationTypes = {
  birthday: 'Happy Birthday!',
  anniversary: 'Congratulations on your anniversary!',
};

export const employeeStatuses = {
  All: '',
  Active: '1',
  Inactive: '0',
};

export const lookupsModels = {
  country: 'Country',
  timezone: 'Timezone',
  location: 'Location',
  jobTitle: 'JobTitle',
  department: 'Department',
  division: 'Division',
  language: 'Language',
  compensation: 'EmployeeCompensation',
  compensationPaySchedule: 'CompensationPaySchedule',
  reason: 'Reason',
  surveyResponse: 'SurveyResponse',
  survey: 'Survey',
  employeeStatus: 'EmployeeStatus',
  assetCategory: 'AssetCategory',
  link: 'Link',
  announcement: 'Announcement',
  job: 'Job',
  jobApplicationFields: 'JobApplicationFields',
  satisfactionSurvey: 'SatisfactionSurvey',
};

export const lookupsFilters = {
  [lookupsModels.employeeStatus]: {
    onlyActive: 'onlyActive',
    getTerminationFields: 'getTerminationFields',
  },
};

export const siteName = 'AM2';

export const hideForNow = false;

export const timeOffPolicyScheduleType = {
  weekly: 'Weekly',
  twice_a_month: 'Twice a month',
  monthly: 'Monthly',
  quarterly: 'Quarterly',
  twice_a_year: 'Twice a year',
  yearly: 'Yearly',
};

export const acceptTypes = {
  allImages: 'image/*',
  currentValidImages: 'image/jpeg,image/png,image/jpg',
};

export const datePeriods = {
  fullMonth: ({
    maxLength = 28,
    withLastDayValue = true,
    startFrom = 0,
    lastDateValue = '-1',
  } = {}) => {
    const formattedFullMothDays = Array.from({ length: maxLength }).map(
      (val, index) => {
        const lastDigit = (index + 1 + startFrom) % 10;
        const lastTwoDigits = (index + 1 + startFrom) % 100;

        let digit = `${index + 1 + startFrom}`;
        if (lastDigit === 1 && lastTwoDigits !== 11) {
          digit += 'st';
        } else if (lastDigit === 2 && lastTwoDigits !== 12) {
          digit += 'nd';
        } else if (lastDigit === 3 && lastTwoDigits !== 13) {
          digit += 'rd';
        } else {
          digit += 'th';
        }

        return { label: digit, value: `${index + 1 + startFrom}` };
      },
    );

    return withLastDayValue
      ? formattedFullMothDays.concat({
          label: 'Last Day',
          value: lastDateValue,
        })
      : formattedFullMothDays;
  },
};

export const tabNames = {
  dashboard: 'Dashboard',
  notifications: 'Notifications',
  administration: 'Administration',
  globalSearch: 'Search',
  settings: 'Settings',
  settingsSurveys: 'Settings Surveys',
  settingsPerformanceManagement: 'Settings Performance Management',
  reports: 'Reports',
  requests: 'Requests',
  editTalentGroup: 'Edit Talent Group',
  createTalentGroup: 'Create Talent Group',
  hiringTalentGroup: 'Hiring Talent Group',
  hiringJobCandidate: 'Hiring Job Candidate',
  editHiringJob: 'Edit Hiring Job',
  createHiringJob: 'Create Hiring Job',
  hiringJob: 'Hiring Job',
  hiring: 'Hiring',
  editEmployees: 'Edit Employee',
  createEmployees: 'Create Employee',
  employeesProfile: 'Employee Profile',
  employees: 'Employees',
  myProfile: 'My Profile',
  userProfile: 'User Profile',
  recruitment: 'Recruitment',
  hr: 'HR',
  files: 'Files',
  calendar: 'Calendar',
  announcements: 'Announcements',
};

export const userStatuses = {
  all: 'All',
  active: 'Active',
  inactive: 'Inactive',
};

export const userStatusMeta = {
  [userStatuses.all]: '',
  [userStatuses.active]: 1,
  [userStatuses.inactive]: 0,
};

export const userStatusesList = [
  userStatuses.all,
  userStatuses.active,
  userStatuses.inactive,
];

export const oauthAPI = {
  microsoftLogin: 'https://login.microsoftonline.com/common/oauth2/v2.0/token',
};

export const authProviders = {
  microsoft: 'microsoft',
  google: 'google',
};

export const fileTypes = {
  directory: 'd',
  file: 'f',
};

export const filterEmployeeTypes = {
  someEmployee: '1',
  all: '0',
};

export const fileStatuses = {
  readable: 0,
  assignable: 1,
};

export const employeeStatusTypes = {
  terminated: 0,
  system: 1,
  custom: 2,
};

export const employeeReasons = {
  termination: '30',
};

export const calendarEventTypes = {
  birthdays: 'birthdays',
  anniversaries: 'anniversaries',
  approvedTimeOff: 'approved_timeoff',
};

export const calendarEventTypeLabels = {
  [calendarEventTypes.birthdays]: 'Birthdays',
  [calendarEventTypes.anniversaries]: 'Anniversaries',
  [calendarEventTypes.approvedTimeOff]: 'Approved Time Off',
};

export const WidgetsTypes = {
  celebrations: 'celebrations',
  whoOut: 'who_out',
  companyHolidays: 'company_holidays',
  welcomeAtomate: 'welcome_atomate',
  candidatesOffers: 'candidates_offers',
  incompleteTrainings: 'incomplete_trainings',
  timeoffRequests: 'timeoff_requests',
  companyLinks: 'company_links',
};

// in ms
export const Time = {
  WidgetStaleQueryTime: 10000,
};

export const AnnouncementStatuses = {
  Published: '10',
  PublishedAndEmail: '11',
  Draft: '0',
};

export const jobFieldTypes = {
  text: 1,
  date: 2,
  file: 3,
  textArea: 4,
  link: 5,
};

export const jobQuestionFieldType = {
  text: 'text',
  select: 'select',
  option: 'options',
};

export const requestsTypes = {
  inbox: 'inbox',
  sent: 'sent',
  signature: 'signature',
  timeoff: 'timeoff',
  performance: 'performance',
  performance_completed: 'performance_completed',
  done: 'done',
  saved: 'saved',
};

export const uploadFileTypes = {
  images: [MIME_TYPES.png, MIME_TYPES.jpeg],
  pdf: MIME_TYPES.pdf,
  rtf: 'application/rtf',
  rtf2: 'text/rtf',
  docs: [MIME_TYPES.doc, MIME_TYPES.docx],
  zip: MIME_TYPES.zip,
  rtfs() {
    return [this.rtf, this.rtf2];
  },
  all() {
    return [...this.images, this.pdf, ...this.docs, this.zip, ...this.rtfs()];
  },
  allExZip() {
    return [...this.images, this.pdf, ...this.docs, ...this.rtfs()];
  },
};

export const satisfactionStatuses = {
  Active: 1,
  Stopped: 0,
  Sended: 2,
  Draft: 4,
};
